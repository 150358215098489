header {
  background: #fff;
  padding: .5rem 0 0 0;
  border-bottom: 1px solid #d8d8d8;
  padding: 1.5rem 0 .5rem 0;
  .title {
    padding: 0rem 0 1rem 3.5rem;
    background-image: url('/assets/img/logo.svg');
    background-repeat: no-repeat;
  h1 {
    margin-top: .5rem;
    color: #000;
    text-transform: uppercase;
    //font-size: 2rem;
    margin-bottom: 0;
    font-size: 1.6rem;
    letter-spacing: .5rem;
    font-weight: 600;
    margin-bottom: 0;
    line-height: .8;
  }
  span {
    text-transform: uppercase;
    font-size: .8rem;
    letter-spacing: .1rem;
  }
}
.menu {
  margin-top: 0;
  font-size: 1.2rem;
  a {
    color: #333;
    i {
      color: #F0483F;
    }
  }
}
}

 .banner {
   padding: 2rem 0;
   background: #464E53;
   margin-bottom: 0;
   border-bottom: 1px solid #fff;
   h2 {
     margin-bottom: 0;
     font-weight: 600;
     font-size: 1.6rem;
   }
 }
main {
  section {
    padding: 4rem 0 2.5rem 0;
    //border-bottom: 1px solid #d8d8d8;
  }
  section#mywork {
    border-bottom: 1px solid #d8d8d8;
  }
  section#aboutme {
    background: #fff;
    border-bottom: 1px solid #d8d8d8;
    text-align: center;
    font-size: 1.3rem;
    span {
      color: #F0483F;
      font-size: 6rem;
      font-family: 'Roboto Mono', monospace;
      font-weight: 700;
      font-style: italic;
      line-height: 1;
      display: inline-block;
      text-transform: lowercase;
      margin-top: 0;
      margin-bottom: 2rem;
    }
    .circle {
      color: #fff;
      border-radius: 70px;
      background: #F0483F;
      width: 100px;
      height: 100px;
      margin-bottom: 1rem;
    }
  }
  .websites {

  }
  .logos {
    margin-top: 2rem;
  }
  .thumbnails {
    font-size: 1rem;
    color: #464E54;
    h3 {
      margin-bottom: 1.5rem;
      border-bottom: 1px solid #464E54;
      font-weight: 600;
    }
    .callout {
      box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.05);
      position: relative;
      border: 1px solid transparent;
      &:hover {
        border: 1px solid #d8d8d8;
      }
    }
    img {
      display: block;
      background-color: #fff;
    }
    .callout:hover span {
      display: block;
      opacity: .8;
    }
    span {
      position: absolute;
      bottom: 0;
      right: 2px;
      display: none;
    }

  }

h2.heading {
 overflow: hidden;
 text-align: center;
 text-transform: uppercase;
}
h2.heading:before,
h2.heading:after {
 background-color: #333;
 content: "";
 display: inline-block;
 height: 2px;
 position: relative;
 vertical-align: middle;
 width: 10%;
}
h2.heading:before {
 right: 0.2em;
 margin-left: -50%;
}
h2.heading:after {
 left: 0.2em;
 margin-right: -50%;
}
  // .heading-group {
  //   position: relative;
  //   margin-bottom: 2rem;
  // }
  // h2.heading {
  // display: inline-block;
  // max-width: 100%;
  // zoom: 1;
  // margin-bottom: 0;
  // font-size: 2.08333rem;
  // }
  //
  // h2.heading:after, h2.heading:before {
  //   content: " ";
  //   display: inline-block;
  //   height: 2px;
  //   margin: 0 15px;
  //   position: absolute;
  //   top: 50%;
  //   width: 80px;
  //   zoom: 1;
  //   background: #333;
  // }
  // h2.heading:after {
  //   left: 62%;
  // }
  // h2.heading:before {
  //   right: 62%;
  // }

}
.reveal {
  h4 {
  border-bottom: 3px solid #EAEAEA;
}
  p {
    border-bottom: 1px solid #EAEAEA;
    padding-bottom: 1rem;
  }
}


footer {
  background-color: #EAEAEA;
  //border-top: 1px solid #d8d8d8;
  padding: 4rem 0 3rem 0;
  //margin-top: 4rem;
}

// @include breakpoint(medium) {
//     main {
//     h2.heading:after {
//       left: 57%;
//     }
//     h2.heading:before {
//       right: 57%;
//     }
//   }
// }
//
// @include breakpoint(large) {
//     main {
//     h2.heading:after {
//       left: 62%;
//     }
//     h2.heading:before {
//       right: 62%;
//     }
//   }
// }

@include breakpoint(medium) {
  .banner {
    h2 {
      font-size: 3.6rem;
    }
}


}
